import React from 'react';
import { Wrench, Home, Shield } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import ServiceTable from './ServiceTable';

/**
 * Services Component
 * 
 * Displays the company's main service offerings in a grid layout.
 * Each service is presented in a table format with consistent styling
 * and clear information hierarchy. The component uses the ServiceTable
 * component to maintain consistency across service presentations.
 */
const Services = () => {
  const { theme } = useTheme();
  
  // Service data with icons, descriptions, and key features
  const services = [
    {
      title: 'Emergency Repairs',
      icon: Wrench,
      items: ['Roof Leaks', 'Plumbing Issues', 'Electrical Problems', '24/7 Response'],
      description: 'In times of disaster, we have you covered. From hurricanes to unexpected leaks, our emergency response team is available 24/7 to minimize damage and restore safety to your property.'
    },
    {
      title: 'Renovations',
      icon: Home,
      items: ['Kitchen Remodeling', 'Bathroom Updates', 'Floor Replacement', 'Custom Solutions'],
      description: 'Transform your space with our expert renovation services. We bring your vision to life with quality craftsmanship, innovative design solutions, and meticulous attention to detail.'
    },
    {
      title: 'Maintenance',
      icon: Shield,
      items: ['Preventive Care', 'Safety Inspections', 'Weather Protection', 'Regular Upkeep'],
      description: 'Prevent issues before they arise with our comprehensive maintenance services. Regular inspections and upkeep keep your property in pristine condition throughout the year.'
    }
  ];

  return (
    <section className={`py-16 ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
    }`} id="services">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Title */}
        <h2 className={`text-3xl font-bold text-center mb-12 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>
          Our Services
        </h2>

        {/* Services Grid */}
        <div className="grid lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceTable key={index} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;