import React from 'react';
import { Star, Home, MessageSquare, CheckCircle } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import FeatureTable from './FeatureTable';

/**
 * WhyChooseUs Component
 * 
 * This component presents the company's unique value propositions and competitive
 * advantages in a clear, structured format. It uses the FeatureTable component
 * to display key benefits with corresponding icons and descriptions.
 * 
 * The component emphasizes:
 * - Years of experience
 * - Local expertise
 * - Communication quality
 * - Work guarantees
 */
const WhyChooseUs = () => {
  const { theme } = useTheme();
  
  // Define features with their corresponding icons and descriptions
  const features = [
    {
      icon: Star,
      title: '15+ Years Experience',
      description: 'Specialized expertise in mobile home repairs and renovations'
    },
    {
      icon: Home,
      title: 'Local Experts',
      description: 'Family-owned business serving our community since 2010'
    },
    {
      icon: MessageSquare,
      title: 'Clear Communication',
      description: 'Direct contact with technicians and transparent pricing'
    },
    {
      icon: CheckCircle,
      title: 'Guaranteed Work',
      description: 'All repairs backed by our satisfaction guarantee'
    }
  ];

  return (
    <section className={`py-16 ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-white'
    }`} id="why-choose-us">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Title */}
        <h2 className={`text-3xl font-bold text-center mb-12 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>
          Why Choose Accurate Repairs?
        </h2>

        {/* Feature Table */}
        <FeatureTable features={features} />
      </div>
    </section>
  );
};

export default WhyChooseUs;