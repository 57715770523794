import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

/**
 * Interface defining the shape of our project card props.
 * This tells TypeScript exactly what properties a project card should receive.
 */
interface ProjectCardProps {
  title: string;       // The title of the project
  description: string; // A brief description of the project
}

/**
 * ProjectCard Component
 * 
 * A sub-component that displays individual project information with
 * a consistent layout and styling. Each card includes an image
 * placeholder, project title, and description.
 * 
 * @param props - The properties passed to the component (title and description)
 */
const ProjectCard: React.FC<ProjectCardProps> = ({ title, description }) => {
  const { theme } = useTheme();
  
  return (
    <div className={`rounded-lg overflow-hidden border ${
      theme === 'dark' ? 'border-gray-700 bg-gray-800' : 'border-gray-300 bg-gray-50'
    }`}>
      <table className="w-full">
        <thead>
          <tr className={
            theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'
          }>
            <th className={`px-6 py-4 text-left ${
              theme === 'dark' ? 'text-white' : 'text-gray-900'
            }`}>
              {title}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Project Image */}
          <tr>
            <td className="px-6 py-4">
              <div className="h-48 bg-gray-200 rounded-lg overflow-hidden">
                <img
                  src="/api/placeholder/400/320"
                  alt={title}
                  className="w-full h-full object-cover"
                />
              </div>
            </td>
          </tr>
          {/* Project Description */}
          <tr className={`border-t ${
            theme === 'dark' ? 'border-gray-700' : 'border-gray-300'
          }`}>
            <td className={`px-6 py-4 ${
              theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {description}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

/**
 * RecentProjects Component
 * 
 * Displays a showcase of recent renovation and repair projects.
 * Projects are presented in a grid layout with consistent styling
 * and clear visual hierarchy. Each project includes an image,
 * title, and brief description.
 */
const RecentProjects = () => {
  const { theme } = useTheme();

  // Project data with titles and descriptions
  const projects: ProjectCardProps[] = [
    {
      title: 'Kitchen Renovation',
      description: 'Complete remodel with modern fixtures'
    },
    {
      title: 'Bathroom Update',
      description: 'Full bathroom modernization'
    },
    {
      title: 'Roof Repair',
      description: 'Storm damage restoration'
    }
  ];

  return (
    <section className={`py-16 ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
    }`} id="projects">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Title */}
        <h2 className={`text-3xl font-bold text-center mb-12 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>
          Recent Projects
        </h2>

        {/* Projects Grid */}
        <div className="grid md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>

        {/* View More Button */}
        <div className="text-center mt-12">
          <Link to="/projects" className={`inline-block px-8 py-3 rounded-lg font-semibold transition-colors ${
            theme === 'dark'
              ? 'bg-blue-600 hover:bg-blue-700 text-white'
              : 'bg-blue-900 hover:bg-blue-800 text-white'
          }`}>
            View More Projects
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RecentProjects;