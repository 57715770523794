import React from 'react';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

/**
 * ThemeToggle Component
 * 
 * A button component that toggles between light and dark themes.
 * Features smooth transitions and appropriate icons for each theme.
 * The styling adapts to provide good contrast in both themes while
 * maintaining visual consistency with the overall design.
 */
const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className={`p-2 rounded-lg transition-colors ${
        theme === 'dark'
          ? 'bg-gray-700 hover:bg-gray-600 text-yellow-300' // Dark mode: subtle background with bright icon
          : 'bg-gray-100 hover:bg-gray-200 text-gray-900'   // Light mode: light background with dark icon
      }`}
      aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} theme`}
    >
      {/* Show sun icon in dark mode, moon icon in light mode */}
      {theme === 'dark' ? (
        <Sun className="w-5 h-5" />
      ) : (
        <Moon className="w-5 h-5" />
      )}
    </button>
  );
};

export default ThemeToggle;