import React from 'react';
import { Phone, Mail, Clock } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

/**
 * ContactSection Component
 * 
 * A comprehensive contact section that combines contact information display
 * and a contact form. The component is split into two main parts:
 * 1. Contact Information Table - displays key contact methods and hours
 * 2. Contact Form - allows users to submit inquiries and request estimates
 * 
 * The component features:
 * - Responsive layout that adapts to different screen sizes
 * - Accessible form inputs with proper labeling
 * - Theme-aware styling with appropriate contrast
 * - Interactive elements with hover states
 * - Clear visual hierarchy of information
 */
const ContactSection = () => {
  const { theme } = useTheme();

  // Contact information data structure
  const contactInfo = [
    {
      icon: Phone,
      label: 'Phone',
      value: '(863) 682-7663',
      link: 'tel:8636827663'
    },
    {
      icon: Mail,
      label: 'Email',
      value: 'contact@mobilehomerepairfl.com',
      link: 'mailto:contact@mobilehomerepairfl.com'
    },
    {
      icon: Clock,
      label: 'Hours',
      value: ['24/7 Emergency Service', 'Regular Hours: Mon-Sat: 8AM-6PM'],
      link: null
    }
  ];

  /**
   * Form submission handler
   * In a real implementation, this would connect to a backend service
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add form submission logic here
    console.log('Form submitted');
  };

  // Common input styles based on theme
  const getInputStyles = () => `
    mt-1 block w-full px-3 py-2 rounded-md shadow-sm
    ${theme === 'dark'
      ? 'bg-gray-700 border-gray-600 text-white focus:ring-blue-500 focus:border-blue-500'
      : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
    }
  `;

  return (
    <section className={`py-16 ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-white'
    }`} id="contact">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className={`text-3xl font-bold mb-4 ${
            theme === 'dark' ? 'text-white' : 'text-gray-900'
          }`}>
            Ready to Start Your Project?
          </h2>
          <p className={`text-xl ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Get your free estimate today - no obligation, just straightforward advice from local experts.
          </p>
        </div>

        {/* Contact Content Grid */}
        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Information Table */}
          <div className={`rounded-lg overflow-hidden border ${
            theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
          }`}>
            <table className="w-full">
              <thead>
                <tr className={
                  theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
                }>
                  <th className={`px-6 py-4 text-left text-lg font-semibold ${
                    theme === 'dark' ? 'text-white' : 'text-gray-900'
                  }`}>
                    Contact Information
                  </th>
                </tr>
              </thead>
              <tbody className={
                theme === 'dark' ? 'bg-gray-800' : 'bg-white'
              }>
                {contactInfo.map((info, index) => (
                  <tr key={index} className={`border-t ${
                    theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <td className="px-6 py-4">
                      <div className="flex items-center gap-4">
                        {/* Icon Container */}
                        <div className={`p-2 rounded-lg ${
                          theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
                        }`}>
                          <info.icon className={
                            theme === 'dark' ? 'text-blue-400' : 'text-blue-900'
                          } />
                        </div>
                        {/* Contact Details */}
                        <div>
                          <p className={`font-semibold ${
                            theme === 'dark' ? 'text-white' : 'text-gray-900'
                          }`}>
                            {info.label}
                          </p>
                          {Array.isArray(info.value) ? (
                            info.value.map((v, i) => (
                              <p key={i} className={
                                theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                              }>
                                {v}
                              </p>
                            ))
                          ) : (
                            info.link ? (
                              <a 
                                href={info.link}
                                className={`${
                                  theme === 'dark' ? 'text-blue-400' : 'text-blue-900'
                                } hover:underline`}
                              >
                                {info.value}
                              </a>
                            ) : (
                              <p className={
                                theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                              }>
                                {info.value}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Contact Form */}
          <div className={`rounded-lg overflow-hidden border ${
            theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
          }`}>
            <div className={
              theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
            }>
              <div className="px-6 py-4">
                <h3 className={`text-lg font-semibold ${
                  theme === 'dark' ? 'text-white' : 'text-gray-900'
                }`}>
                  Get Your Free Estimate
                </h3>
              </div>
            </div>
            <div className={`p-6 ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-white'
            }`}>
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Name Input */}
                <div>
                  <label htmlFor="name" className={`block text-sm font-medium ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}>
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className={getInputStyles()}
                    required
                  />
                </div>

                {/* Email Input */}
                <div>
                  <label htmlFor="email" className={`block text-sm font-medium ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}>
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={getInputStyles()}
                    required
                  />
                </div>

                {/* Phone Input */}
                <div>
                  <label htmlFor="phone" className={`block text-sm font-medium ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}>
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className={getInputStyles()}
                    required
                  />
                </div>

                {/* Message Input */}
                <div>
                  <label htmlFor="message" className={`block text-sm font-medium ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}>
                    Project Details
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    className={getInputStyles()}
                    required
                  />
                </div>

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className={`w-full px-6 py-3 rounded-lg font-semibold transition-colors ${
                      theme === 'dark'
                        ? 'bg-blue-600 hover:bg-blue-700 text-white'
                        : 'bg-blue-900 hover:bg-blue-800 text-white'
                    }`}
                  >
                    Get Free Estimate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;