import React from 'react';
import { useTheme } from '../context/ThemeContext';

/**
 * ServiceTable Component
 * 
 * A reusable table component that displays service information in a structured format.
 * Each service is presented with an icon, title, list of features, and description.
 * The component uses a table layout for better organization and readability.
 */
interface ServiceTableProps {
  service: {
    title: string;
    icon: any;  // Icon component from lucide-react
    items: string[];
    description: string;
  };
}

const ServiceTable: React.FC<ServiceTableProps> = ({ service }) => {
  const { theme } = useTheme();
  const Icon = service.icon;

  return (
    <div className={`rounded-lg overflow-hidden border ${
      theme === 'dark' 
        ? 'border-gray-700 bg-gray-800' 
        : 'border-gray-300 bg-gray-50'
    }`}>
      <table className="w-full">
        {/* Table Header with Service Title and Icon */}
        <thead>
          <tr className={
            theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'
          }>
            <th className="px-6 py-4 text-left" colSpan={2}>
              <div className="flex items-center gap-3">
                {/* Icon container with theme-specific styling */}
                <div className={`p-2 rounded-lg ${
                  theme === 'dark' ? 'bg-blue-900' : 'bg-blue-100'
                }`}>
                  <Icon className={`w-6 h-6 ${
                    theme === 'dark' ? 'text-blue-100' : 'text-blue-900'
                  }`} />
                </div>
                <span className={`text-xl font-semibold ${
                  theme === 'dark' ? 'text-white' : 'text-gray-900'
                }`}>
                  {service.title}
                </span>
              </div>
            </th>
          </tr>
        </thead>

        {/* Table Body with Service Items and Description */}
        <tbody>
          {/* Service Items */}
          {service.items.map((item, index) => (
            <tr key={index} className={`border-t ${
              theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
            }`}>
              <td className={`px-6 py-3 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <div className="flex items-center gap-2">
                  {/* Bullet point for list items */}
                  <div className={`w-2 h-2 rounded-full ${
                    theme === 'dark' ? 'bg-blue-400' : 'bg-blue-900'
                  }`} />
                  {item}
                </div>
              </td>
            </tr>
          ))}
          
          {/* Service Description */}
          <tr className={`border-t ${
            theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
          }`}>
            <td className={`px-6 py-4 italic ${
              theme === 'dark' ? 'text-gray-400' : 'text-gray-600'
            }`} colSpan={2}>
              {service.description}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServiceTable;