import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Hero from './components/Hero';
import Services from './components/Services';
import WhyChooseUs from './components/WhyChooseUs';
import RecentProjects from './components/RecentProjects';
import ContactSection from './components/ContactSection';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Gallery } from './components/Gallery';
import { useTheme } from './context/ThemeContext';

// Home component for the landing page
const Home = () => {
  return (
    <>
      <Hero />
      <Services />
      <WhyChooseUs />
      <RecentProjects />
      <ContactSection />
    </>
  );
};

// Layout component to maintain consistent theme and navigation
const Layout = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();
  
  return (
    <div className={`min-h-screen ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
    }`}>
      <Navbar/>
      {children}
      <Footer />
    </div>
  );
};

// Main App component wraps everything with ThemeProvider for global theme access
const App = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Gallery />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;