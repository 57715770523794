import React from 'react';
import { Phone } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

/**
 * Hero Component
 * 
 * This component serves as the main banner section of the website.
 * It features the company's primary message, value proposition,
 * and main call-to-action buttons.
 * 
 * The component adapts its styling based on the current theme
 * and maintains readability in both light and dark modes.
 */
const Hero = () => {
  const { theme } = useTheme();
  
  return (
    <div className={`relative ${
      // We use a darker background for dark mode while maintaining the professional look
      theme === 'dark' ? 'bg-gray-800' : 'bg-blue-900'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          {/* Main heading with consistent white text for contrast */}
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            Quality Mobile Home Repairs
          </h1>
          
          {/* Subheading with slightly muted text for visual hierarchy */}
          <p className="text-xl md:text-2xl mb-8 text-gray-200">
            Professional repairs and renovations by local experts who care about your home
          </p>
          
          {/* Call-to-action buttons with theme-aware styling */}
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
            <a
              href="#contact"
              className={`px-8 py-3 rounded-lg font-semibold text-lg transition-colors ${
                theme === 'dark'
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-white text-blue-900 hover:bg-blue-50'
              }`}
            >
              Get Free Estimate
            </a>
            
            {/* Phone number with icon for immediate contact */}
            <a
              href="tel:8636827663"
              className="flex items-center gap-2 text-lg text-gray-200 hover:text-white transition-colors"
            >
              <Phone size={24} />
              <span>(863) 682-7663</span>
            </a>
          </div>
        </div>
      </div>
      
      {/* Gradient overlay for smooth transition to the next section */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-gray-50 to-transparent dark:from-gray-900" />
    </div>
  );
};

export default Hero;