import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

/**
 * Footer Component
 * 
 * A comprehensive footer section that provides easy access to important
 * information and navigation links. The footer is organized into columns
 * for better content structure and includes:
 * 
 * - Company description
 * - Quick navigation links
 * - Services overview
 * - Contact information
 * 
 * Features:
 * - Responsive grid layout
 * - Theme-aware styling
 * - Semantic HTML structure
 * - Accessible navigation
 * - Interactive elements with hover states
 */
const Footer = () => {
  const { theme } = useTheme();
  
  return (
    <footer className={`${
      theme === 'dark' ? 'bg-gray-900' : 'bg-blue-900'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Main Footer Content Grid */}
        <div className="grid md:grid-cols-4 gap-8">
          {/* Company Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Accurate Repairs
            </h3>
            <p className={
              theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
            }>
              Professional mobile home repairs and renovations serving our local 
              community since 2010.
            </p>
          </div>
          
          {/* Quick Links Navigation */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Quick Links
            </h3>
            <ul className="space-y-2">
              {['Services', 'Projects', 'Contact'].map((link) => (
                <li key={link}>
                  <a
                    href={`#${link.toLowerCase()}`}
                    className={`${
                      theme === 'dark'
                        ? 'text-gray-400 hover:text-white'
                        : 'text-blue-100 hover:text-white'
                    } transition-colors`}
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          
          {/* Services Overview */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Services
            </h3>
            <ul className="space-y-2">
              {['Emergency Repairs', 'Renovations', 'Maintenance'].map((service) => (
                <li
                  key={service}
                  className={
                    theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
                  }
                >
                  {service}
                </li>
              ))}
            </ul>
          </div>
          
          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Contact
            </h3>
            <ul className="space-y-4">
              {/* Phone Contact */}
              <li className="flex items-center gap-2">
                <Phone size={20} className={
                  theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
                } />
                <a
                  href="tel:8636827663"
                  className={`${
                    theme === 'dark'
                      ? 'text-gray-400 hover:text-white'
                      : 'text-blue-100 hover:text-white'
                  } transition-colors`}
                >
                  (863) 682-7663
                </a>
              </li>
              
              {/* Email Contact */}
              <li className="flex items-center gap-2">
                <Mail size={20} className={
                  theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
                } />
                <a
                  href="mailto:contact@mobilehomerepairfl.com"
                  className={`${
                    theme === 'dark'
                      ? 'text-gray-400 hover:text-white'
                      : 'text-blue-100 hover:text-white'
                  } transition-colors`}
                >
                  contact@mobilehomerepairfl.com
                </a>
              </li>
              
              {/* Location Information */}
              <li className="flex items-center gap-2">
                <MapPin size={20} className={
                  theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
                } />
                <span className={
                  theme === 'dark' ? 'text-gray-400' : 'text-blue-100'
                }>
                  Lakeland, FL
                </span>
              </li>
            </ul>
          </div>
        </div>
        
        {/* Copyright Section */}
        <div className={`border-t mt-8 pt-8 text-center ${
          theme === 'dark' 
            ? 'border-gray-800 text-gray-400' 
            : 'border-blue-800 text-blue-100'
        }`}>
          <p>&copy; {new Date().getFullYear()} Accurate Repairs. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;