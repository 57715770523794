import React from 'react';
import { useTheme } from '../context/ThemeContext';

/**
 * FeatureTable Component
 * 
 * A responsive table component that displays company features and benefits.
 * This component is designed to present key differentiators in a clear,
 * organized format with consistent styling and accessibility features.
 * 
 * Features:
 * - Responsive design that adapts to different screen sizes
 * - Theme-aware styling with appropriate contrast ratios
 * - Icon integration for visual enhancement
 * - Semantic HTML structure for better accessibility
 */
interface FeatureTableProps {
  features: {
    icon: any;  // Icon component from lucide-react
    title: string;
    description: string;
  }[];
}

const FeatureTable: React.FC<FeatureTableProps> = ({ features }) => {
  const { theme } = useTheme();

  return (
    <div className={`w-full rounded-lg overflow-hidden border ${
      theme === 'dark' ? 'border-gray-700' : 'border-gray-300'
    }`}>
      <table className="w-full">
        {/* Table Header */}
        <thead>
          <tr className={
            theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
          }>
            <th className={`px-6 py-4 text-left ${
              theme === 'dark' ? 'text-white' : 'text-gray-900'
            }`}>
              Feature
            </th>
            {/* Description column hidden on mobile for better responsiveness */}
            <th className={`px-6 py-4 text-left hidden md:table-cell ${
              theme === 'dark' ? 'text-white' : 'text-gray-900'
            }`}>
              Description
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody className={
          theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'
        }>
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <tr key={index} className={`border-t ${
                theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
              }`}>
                {/* Feature Title with Icon */}
                <td className="px-6 py-4">
                  <div className="flex items-center gap-3">
                    <div className={`p-2 rounded-lg ${
                      theme === 'dark' ? 'bg-blue-900' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-6 h-6 ${
                        theme === 'dark' ? 'text-blue-100' : 'text-blue-900'
                      }`} />
                    </div>
                    <span className={
                      theme === 'dark' ? 'text-white' : 'text-gray-900'
                    }>
                      {feature.title}
                    </span>
                  </div>
                </td>
                {/* Feature Description - Hidden on mobile */}
                <td className={`px-6 py-4 hidden md:table-cell ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {feature.description}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FeatureTable;