import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Phone } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import ThemeToggle from './ThemeToggle';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  const navLinks = [
    { name: 'Services', href: '/#services' },
    { name: 'Why Choose Us', href: '/#why-choose-us' },
    { name: 'Projects', to: '/projects' },
    { name: 'Contact', href: '/#contact' },
  ];

  return (
    <nav className={`${
      theme === 'dark' 
        ? 'bg-gray-900 border-gray-700' 
        : 'bg-white border-gray-200'
    } border-b sticky top-0 z-50`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className={`font-bold text-xl ${
              theme === 'dark' ? 'text-white' : 'text-blue-900'
            }`}>
              Accurate Repairs
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => 
              link.to ? (
                <Link
                key={link.name}
                to={link.to}
                className={`${
                  theme === 'dark' 
                    ? 'text-gray-300 hover:text-white' 
                    : 'text-gray-600 hover:text-blue-900'
                } transition-colors`}
              >
                {link.name}
              </Link>
              ) : (
              <a
                key={link.name}
                href={link.href}
                className={`${
                  theme === 'dark' 
                    ? 'text-gray-300 hover:text-white' 
                    : 'text-gray-600 hover:text-blue-900'
                } transition-colors`}
              >
                {link.name}
              </a>
              )
            )}
            <a
              href="tel:8636827663"
              className={`flex items-center gap-2 font-semibold ${
                theme === 'dark' ? 'text-blue-400' : 'text-blue-900'
              }`}
            >
              <Phone size={20} />
              <span>(863) 682-7663</span>
            </a>
            <ThemeToggle />
          </div>

          <div className="md:hidden flex items-center gap-4">
            <ThemeToggle />
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={theme === 'dark' ? 'text-white' : 'text-gray-600'}
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className={`px-2 pt-2 pb-3 space-y-1 ${
            theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
          }`}>
            {navLinks.map((link) => 
              link.to ? (
                <Link
                to={link.to}
                className={`block px-3 py-2 ${
                  theme === 'dark'
                    ? 'text-gray-300 hover:text-white'
                    : 'text-gray-600 hover:text-blue-900'
                } transition-colors`}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </Link>
              ) : (
              <a
                href={link.href}
                className={`block px-3 py-2 ${
                  theme === 'dark'
                    ? 'text-gray-300 hover:text-white'
                    : 'text-gray-600 hover:text-blue-900'
                } transition-colors`}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </a>
              )
            )}
            <a
              href="tel:8636827663"
              className={`block px-3 py-2 font-semibold ${
                theme === 'dark' ? 'text-blue-400' : 'text-blue-900'
              }`}
              onClick={() => setIsOpen(false)}
            >
              <div className="flex items-center gap-2">
                <Phone size={20} />
                <span>(863) 682-7663</span>
              </div>
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;